<template>
  <div>
    <rxNavBar title="请假审核" androidOrIOSFlag="true"></rxNavBar>
    <!--        搜索框-->
    <div class="head">
      <div class="searchDiv">
        <div class="search">
          <input placeholder="请输入申请人、请假事由" v-model="inputValue" @keyup.enter="search">
        </div>
      </div>
      <!--            下拉-->
      <div class="downMenu" @click="showPicker = true">
        <div class="downMenuValue">{{ columns[number] ? columns[number].dictionaryTitle : '未审批' }}</div>
        <div :class="showPicker ? 'uparrows' : 'arrows'"></div>
      </div>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar :columns="columns" :default-index="number" value-key="dictionaryTitle" @cancel="showPicker = false"
                    @confirm="onConfirm"/>
      </van-popup>
    </div>

    <div class="reviews">
      <van-pull-refresh v-model="isLoading"  @refresh="onRefresh" >
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="noContent ? '' : '已经到底了'"
            @load="onLoad"
        >
          <div class="review" v-for="(item,index) in askForLeaveList" :key="index">
            <div :class="item.approvalStatus=='0'?'top-title-orange':item.approvalStatus=='1'?'top-title-green':'top-title-red'">
              {{ item.approvalStatusStr }}
            </div>
            <div class="vacationForm">
              <div class="vacationType"> <span>{{ item.vacationTypeStr }}</span></div>
              <div class="start_endTime"> <span>{{ item.vacationBeginTime  }}</span>
                <span class="letter-sp">至</span>
                <span>{{ item.vacationEndTime }}</span>
              </div>
              <div><span class="text">申请人:</span><span class="content">{{ item.vacationStaff }}</span></div>
              <div><span class="text">时长:</span><span class="content">{{ item.vacationTimeLong }}天</span></div>
              <div><span class="text">工作交接人:</span><span class="content">{{ item.handoverStaff ? item.handoverStaff : '暂无'}}</span></div>
              <div><span class="text">事由:</span><span class="content">{{ item.remarks }}</span></div>
            </div>
            <div class="line"></div>

            <div class="bottom">
              <div class="time" v-if="item.approvalStatus==0">申请时间 : {{ item.addTime }}</div>
              <div class="time" v-if="item.approvalStatus==2">驳回时间 : {{ item.addTime }}</div>
              <div class="time" v-if="item.approvalStatus==1">通过时间 : {{ item.addTime }}</div>
              <div class="check" v-if="item.approvalStatus==0&&checkAuthList(authButtonsList,'approval')"
                   @click="skip(item)">审核
              </div>
              <div class="lookCheck" v-if="item.approvalStatus==2 || item.approvalStatus==1" @click="skips(item)">查看</div>
            </div>
          </div>
        </van-list>
        <common-empty v-if="noContent"></common-empty>
      </van-pull-refresh>


    </div>

  </div>
</template>

<script>
import {NavBar, Picker, popup, field, List, PullRefresh} from 'vant';
import RxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {queryBaseData, vacationListInit} from "../../../getData/getData";
import {
  getStaffFuntionModelList
} from "../../../getData/getData";
import {checkAuthList} from "../../../libs/rongxunUtil";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar"

export default {
  components: {
    rxNavBar,
    [NavBar.name]: NavBar,
    [Picker.name]: Picker,
    [popup.name]: popup,
    [field.name]: field,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  name: "reviewOfApplyForHouse",
  data() {
    return {
      isLoading: '',
      noContent: false,
      loading: false,
      finished: false,
      //类型
      number: 1,
      //当前页
      page: 1,
      //权限
      authButtonsList: [],
      //搜索绑定值
      inputValue: '',
      //列表初始化返回数组值
      askForLeaveList: [],
      //下拉选择
      // columns :['未审批','已审批','已驳回'],
      columns: [
        {dictionaryValue: '',dictionaryTitle: '全部类型'}
      ],
      //下拉的弹窗判断
      showPicker: false,
      //下拉框的默认选项
      value: '未审批',
      //审批状态
      approvalStatus: '1'
    }
  },
  created() {
    //初始化字典项审批状态
    this.initDb()
    //查看本地是否存在有下拉选择的缓存
    let currentLabel = JSON.parse(localStorage.getItem('currentLabel')); //先读取local里存储的历史记录
    if (currentLabel !== null) {
      if (currentLabel !== '') {
        this.number = Number(currentLabel);
        console.log(this.number)
      }
    }
    if(JSON.parse(localStorage.getItem('inputValue'))){
      this.inputValue = JSON.parse(localStorage.getItem('inputValue'))
    }
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if(from.name == null){
      localStorage.removeItem('currentLabel');
      localStorage.removeItem('type');
      localStorage.removeItem('inputValue');
      localStorage.removeItem('currentLabelPublic');
    }
    next()
  },
  mounted() {
    //页面初始化数据
    //this.initAskForLeave()
    //权限
    this.getStaffFuntionModelList();

  },
  methods: {
    checkAuthList,
    //s权限方法
    getStaffFuntionModelList() {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'superiorLeave_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
        })
      })
    },
    //下拉框
    onConfirm(value, index) {
      if(value){
        localStorage.setItem('currentLabel', JSON.stringify(index))
        this.number = index
      }
      this.askForLeaveList = [];
      this.page = 1;
      this.finished = false;
      this.showPicker = false
      this.loading = true;
      this.noContent = false

      this.onLoad()
    },
    onRefresh() {
      this.askForLeaveList = [];
        this.page = 1;
        this.finished = false
        this.loading = true
        this.noContent = false
        this.isLoading = false
        this.isLoading = false
        this.onLoad();
      responseUtil.alertMsg(this,"刷新成功")
    },
    //搜索事件
    search() {
      if(this.inputValue!=''){
        localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
      }else{
        localStorage.removeItem('inputValue')
      }
      this.askForLeaveList = [];
      this.page = 1;
      this.finished = false
      this.loading = true
      this.noContent = false
      this.onLoad()
    },
    onLoad() {
      let that = this
      let initData = {
        staff_id: getStaffId(),
        currentPage: that.page,
        numberPage: 3,
        approvalStatus: that.number>0 ? that.number-1 : '',
        remarks: that.inputValue
      }
      vacationListInit(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          let count = response.data.data.pageCount
          let list = response.data.data.data
          console.log(list)
          for (let i = 0; i < list.length; i++) {
            let a = list[i].vacationTimeLong / 24
            list[i].vacationTimeLong = Math.ceil(a)
            that.askForLeaveList.push(list[i])
          }
          // 加载状态结束
          that.loading = false;
          // 数据全部加载完成
          if (that.askForLeaveList.length >= count) {
            that.finished = true;
          }
          if (1 == that.page && (!list || 0 == list.length)) {
            that.noContent = true
          } else {
            that.noContent = false
            that.page++;
          }

        })
      })
    },
    //初始化字典
    initDb: function () {
      let that = this
      let initData = {}
      initData.dbName = []
      initData.fdName = ['VACATIONTYPEMAP', 'APPROVESTATUSMAP']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          // console.log(response.data.data.APPROVESTATUSMAP)
          let list = response.data.data.APPROVESTATUSMAP
          for (let i = 0; i < list.length; i++) {
            that.columns.push(list[i])
          }
        })
      })
    },
    //审批
    skip(item) {
      this.$router.push({
        name: 'applyLeave',
        query: {
          id: item.id,
          handoverStaff: item.handoverStaff,
        }
      })
    },
//审批详情
    skips(item) {
      this.$router.push({
        name: 'LeaveReviewDetails',
        query: {
          id: item.id,
          handoverStaff: item.handoverStaff,
        }
      })
    }

  }
}
</script>

<style scoped lang="less">
  *{
    padding: 0;
    margin: 0;
  }
.searchDiv {
  width: 100%;
  height: 40px;

  .search {
    width: 90%;
    height: 40px;
    margin: 10px auto;
    border-radius: 20px;
    background-color: #eeeeee;
    display: flex;

    input {
      width: 80%;
      background-color: #eeeeee;
      border: none;
      font-size: 15px;
    }
  }

  .search:before {
    content: '';
    display: block;
    background: url("../../../assets/images/search.png") no-repeat center center;
    background-size: 50% 50%;
    width: 40px;
    height: 40px;
  }

  input::placeholder {
    font-size: 15px;
  }
}

.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
  //下拉框
  .downMenu {
    height: 28px;
    width: 20%;
    margin: 15px 15px 0;
    line-height: 40px;
    display: flex;
    align-items: center;
    .downMenuValue {
      /*width: 14%;*/
      font-size: 14px;
      color: #FF5D3B;
    }

    .arrows {
      height: 0;
      width: 0;
      border: 3px solid;
      border-color: black transparent transparent transparent;
      margin-top: 1px;
      margin-left: 8px;
    }

    .uparrows {
      height: 0;
      width: 0;
      border: 3px solid;
      border-color: transparent transparent black transparent;
      margin-bottom: 6px;
      margin-left: 8px;
    }
  }
}

/*至字与时间的间距*/
.letter-sp {
  margin-left: 4px;
  margin-right: 4px;
}

.line {
  width: 100%;
  border: 0.5px solid #D8D8D8;
}

/*请假表*/
.vacationForm {
  /*height: 168px;*/
  width: 315px;
  margin-left: 15px;
  margin-bottom: 15px;
  /*line-height: 30px;*/
}

.vacationForm div {
  line-height: 20px;
}

/*请假类型*/
.vacationType {
  color: #ff5809;
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
}

/*请假开始-结束Div*/
.start_endTime {
  color: #ff5809;
  font-size: 14px;
  font-weight: bold;
  margin-top: 6px;
}
/*假单label*/
.text {
  font-weight: bold;
  font-size: 14px;
  margin-right: 0.2rem;
  /*letter-spacing: 1px;*/
}

/*请假填写的内容*/
.content {
  font-size: 13px;
  color: #9a9a9a;
}

/*申请状态DIv样式*/
.types {
  width: 100%;
  height: 43px;
  display: flex;
  /*margin-top: 40px;*/
}

.type {
  width: auto;
  height: 15px;
  font-size: 14px;
  color: #fe5e3a;
  margin: 17px 0 0 15px;
}

.small-icon {
  width: 8px;
  height: 8px;
  margin: 24px 0 0 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  margin: 20px 0 0 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}

.selectList {
  position: fixed;
  z-index: 9;
  width: 346px;
  height: 107px;
  top: 83px;
  left: 14px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.select {
  width: 100%;
  height: 53px;
  line-height: 53px;
  font-size: 14px;
  text-align: center;
  color: #999999;
}

.selectActive {
  width: 100%;
  height: 53px;
  line-height: 53px;
  font-size: 14px;
  text-align: center;
  color: #fe5e3a;
}

.h-line {
  height: 1px;
  margin: 0 5px 0 10px;
  background-color: white;
}

.reviews {
  width: 100%;
  overflow: hidden;
  height: auto;
  position: relative;
  top: 94px;
}

.review {
  margin: 0 15px 15px;
  /*height: 264px;*/
  width: 345px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.top-title-orange,.top-title-green,.top-title-red {
  width: 38px;
  height: 15px;
  font-size: 10px;
  text-align: center;
  line-height: 17px;
  border-radius: 8px 0 ;
  color: white;
}
.top-title-orange{
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}
.top-title-green {
  background: linear-gradient(to right, #63cb96, #30d0ac);
}
.top-title-red {
  background: linear-gradient(to right, #fc401b, #e02321);
}

.bottom {
  width: 345px;
  height: 60px;
  /*display: flex;*/
  align-items: center;
}

.bottom .time {
  float: left;
  width: 143px;;
  height: 24px;
  text-align: left;
  line-height: 26px;
  font-size: 14px;
  color: #999999;
  margin-left: 15px;
  margin-top: 18px;
}

.bottom .look {
  float: left;
  width: 80px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 32px;
  color: #fe5e3a;
  margin-left: 87px;
  border-radius: 8px;
  border: 1px solid transparent;
  background-image: linear-gradient(white, white), linear-gradient(to right, #ffd59e, #ffa08a);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  margin-top: 13px;
}

.check,.lookCheck{
  margin-top: 13px;
  float: left;
  width: 80px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 31px;
  margin-left: 87px;
  border-radius: 8px;
}
.check{
  color: white;
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}
.lookCheck{
  color: #fe5e3a;
  border: 1px solid transparent;
  background-image: linear-gradient(white,white),linear-gradient(to right,#ffbe72,#ff6c41);
  background-origin: border-box;
  background-clip: padding-box,border-box;
}


input::placeholder {
  color: #d5d5d5;
}

</style>
